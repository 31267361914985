body {
	font-family: Arial;
	color: #3B3B3B;
}



.text-grey {
	color: #B3B3B3;
}



.bg-grey {
	background-color: #B3B3B3;
}
.bg-lightgrey {
	background-color: #F5F5F5;
}



.text-bold {
	font-weight: bold;
}



.shadow-sm {
	box-shadow: 0 4px 16px rgba(205, 209, 218, 1) !important;
}

.right-logo {
	margin-right: 50px;
	margin-left: 30px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	pointer-events: none;
}

.left-logo {
	margin-right: 20px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	pointer-events: none;
}

.logout-margin {
	margin-top: 29px;
}



.remove-padding {
	padding-left: 0;
	padding-right: 0;
}
.remove-margin-top-bootom{
margin-bottom: 0 !important;
margin-top: 0 !important;

}
.container-center{
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 700px;;
}
.hr-home{
width: 200px;
height: 4px;
background-color: #8cbe01;
}
